main {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  justify-self: center;
  margin: 10px auto 40px auto;
  max-width: 810px;
  position: relative;
  top: 0px;
  width: 90%;

  section ~ img,
  section > img {
    margin: 50px 0px !important;
  }

  section ~ .theory__video-asset {
    margin-top: 1vw;
    margin-bottom: 1vw;
  }

  .theory__video-asset ~ .caption {
    margin-bottom: 50px;
    padding-top: 0px;
  }

  section {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0px 40px;
    max-width: 690px;
    width: 100%;
  }

  .theoryPage__title {
    font-size: 44px;
    font-weight: 600;
    font-weight: bold;
    margin-bottom: 40px;
    &:last-child {
      margin-bottom: 0px;
    }
    &--center {
      text-align: center;
      width: 100%;
    }
  }

  .theoryPage__text {
    font-family: $basicSans;
    font-size: 18px;
    line-height: 30px;
    max-width: 690px;
    text-align: left;

    &--bold {
      font-weight: 600;
    }

    a {
      font-family: $basicSans;
      font-size: 18px;
      font-weight: 600;
      line-height: 30px;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .theoryPage__blockquote {
    font-size: 22px;
    font-style: italic;
    font-weight: 600;
    line-height: 32px;
    margin: 30px 0;
  }

  h1.theoryPage__title + p.theoryPage__text--bold {
    margin-bottom: 30px;
  }
  p.theoryPage__text--bold + .theoryPage__subtitle {
    margin-top: 0px;
  }

  .theoryPage__ul {
    font-weight: 200;
    list-style-type: none;
    padding-left: 25px;
    position: relative;

    li {
      &:before {
        content: "·";
        font-size: 70px;
        left: 0;
        line-height: 36px;
        position: absolute;
      }
    }
    &--prikkel {
      li:before {
        color: $prikkel-basis;
      }
    }
    &--project {
      li:before {
        color: $project-basis;
      }
    }
    &--school {
      li:before {
        color: $school-basis;
      }
    }
    &--onderzoekend {
      li:before {
        color: $valies-onderzoekend;
      }
    }
    &--samen {
      li:before {
        color: $valies-samen;
      }
    }
    &--actiegericht {
      li:before {
        color: $valies-actiegericht;
      }
    }
  }

  .navbar-bottom {
    align-items: stretch;
    display: flex;
    gap: -2px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 50px;
    justify-content: center;
    margin-top: 50px;
    width: 100%;

    .navbar-bottom__link {
      align-items: center;
      display: flex;
      font-size: 14px;
      justify-content: center;
      min-width: 100px;

      &.navbar-bottom__up {
        border-left: 0px;
        border-radius: 0px;
        border-right: 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .grid-image {
          font-weight: bold;
          padding-bottom: 5px;
          width: 20px;
        }

        &:hover {
          .grid-image {
            filter: invert(1);
          }
        }
      }
      &.navbar-bottom__back {
        border-radius: 5px 0px 0px 5px;
        width: 33%;
      }
      &.navbar-bottom__next {
        border-radius: 0px 5px 5px 0px;
        width: 33%;
      }
    }
  }
}

.theoryPage__table {
  padding: 0;
  display: flex;
  flex-wrap: wrap;

  > div {
    flex: 50%;
    padding: 40px;
  }

  &--prikkel {
    > div {
      &:nth-child(1),
      &:nth-child(4) {
        background-color: $prikkel-basis;
        color: white;
      }
    }
  }
  &--project {
    > div {
      &:nth-child(1),
      &:nth-child(4) {
        background-color: $project-basis;
        color: white;
      }
    }
  }
  &--school {
    > div {
      &:nth-child(1),
      &:nth-child(4) {
        background-color: $school-basis;
        color: white;
      }
    }
  }
}

.theory__text-emphasis {
  border-radius: 5px;
  color: white;
  padding: 3px;
  &--prikkel {
    background-color: $prikkel-basis;
  }
  &--project {
    background-color: $project-basis;
  }
  &--school {
    background-color: $school-basis;
  }
}
.theory__text-emphasis--bold {
  font-weight: 600;
}

.theory__title-emphasis {
  border-radius: 5px;
  color: black;
  padding: 3px;
  &--prikkel {
    background-color: $prikkel-basis;
  }
  &--project {
    background-color: $project-basis;
  }
  &--school {
    background-color: $school-basis;
  }
}

.theoryPage__subtitle {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 40px;
  margin-top: 80px;
  position: relative;
  & ~ .theoryPage__sub-subtitle {
    margin-top: 20px;
  }
  .theory__title-before {
    font-size: 1.1rem;
    left: -60px;
    position: absolute;
    img {
      width: 40px;
    }
  }

  &--marked {
    border-radius: 5px;
    color: white;
    display: inline-block;
    padding: 3px;

    .theory__title-before {
      top: -7px !important;
    }

    &-onderzoekend {
      background: $valies-onderzoekend;
    }

    &-samen {
      background: $valies-samen;
    }

    &-actiegericht {
      background: $valies-actiegericht;
    }
  }
}

.theory__sub-subtitle {
  &--center {
    margin-bottom: 50px;
    text-align: center;
  }
}

.theory__asset,
.theoryPage__asset {
  margin: 50px auto !important;
  max-width: 100%;
}
.theory__video-asset {
  display: flex;
  flex-direction: column;
  margin: 1vw auto;
  width: 100%;
  position: relative;

  div {
    height: 0;
    margin: auto;
    z-index: 1;
    padding-top: 25px;
    padding-bottom: 56.25%;
    display: block;
    overflow: hidden;
    iframe {
      display: block;
      top: 0;
      border-radius: 8px;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 5;
      position: absolute;
    }
  }
}
small.caption {
  font-family: $basicSans !important;
  padding: 15px 0px;

  a {
    font-family: $basicSans !important;
    &:hover {
      transition: all 0.3s;

      text-decoration: underline;
    }
  }

  &--prikkel {
    color: $prikkel-basis;
  }
  &--project {
    color: $project-basis;
  }
  &--school {
    color: $school-basis;
  }
}

.link-inline {
  font-family: $basicSans;
  text-decoration: underline;

  &--prikkel {
    color: $prikkel-basis;
  }
  &--project {
    color: $project-basis;
  }
  &--school {
    color: $school-basis;
  }
}
.theoryPage__sub-subtitle {
  font-size: 26px;
  margin-bottom: 20px;
  margin-top: 0;
  &:last-child {
    margin-bottom: 0px;
  }

  &-sub {
    font-size: 18px;
    line-height: 30px;
    font-weight: 600;
  }
}

aside {
  background-color: white;
  margin: 20px -20px 80px -20px;
  padding: 60px;
  border-radius: 8px;

  .theoryPage__title {
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 0;
    text-align: center;
  }

  .theoryPage__subtitle {
    position: relative;
    font-size: 18px;
    font-weight: bold;
    margin: 40px 0 30px 60px;
    line-height: 24px;

    .theory__title-before {
      top: -10px;
    }
  }

  > .theoryPage__school {
    padding: 0;

    &:before {
      display: none;
    }
  }

  .theoryPage__sub-subtitle:first-of-type {
    margin-top: 0;
  }
}

.theoryPage__school {
  padding: 30px 60px 20px 60px;
  &--middelen {
    position: relative;
    overflow: hidden;

    &:before {
      content: "";
      position: absolute;
      width: 4px;
      height: 100%;
      background: #5f5b93;
      left: 0;
      top: 0;
    }

    > span {
      color: #5f5b93;
      display: block;
      font-weight: 500;
      margin-bottom: 15px;
    }

    .theoryPage__ul--school li:before {
      color: #5f5b93;
    }
  }

  &--leiderschap {
    position: relative;
    overflow: hidden;

    &:before {
      content: "";
      position: absolute;
      width: 4px;
      height: 100%;
      background: #b15477;
      left: 0;
      top: 0;
    }

    > span {
      color: #b15477;
      display: block;
      font-weight: 500;
      margin-bottom: 15px;
    }

    .theoryPage__ul--school li:before {
      color: #b15477;
    }
  }
}

.btn-wrapper {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  a:last-of-type {
    margin-left: 20px;
  }

  &--project {
    .btn-dark {
      background: $project-basis;
      border-color: $project-basis;
    }
  }
}

.theoryPage__flex {
  display: flex;
  margin: 30px 0 50px 0;

  > div {
    width: 70%;

    .theoryPage__asset {
      margin: 0 !important;
    }
  }

  &--left {
    > div {
      margin-right: 30px;
    }
  }

  &--right {
    > div {
      margin-left: 30px;
    }
  }

  .theoryPage__text {
    width: 100%;
  }
}

.theoryPage__ol {
  counter-reset: li;
  list-style: none;
  padding: 0;
  font-family: basic-sans, serif;
  margin-top: 80px;

  > li {
    position: relative;
    font-size: 36px;
    font-weight: bold;
    line-height: 36px;

    &:before {
      background: $school-basis;
      border-radius: 50%;
      color: $valies-bg;
      content: counter(li);
      counter-increment: li;
      font-size: 18px !important;
      height: 30px;
      left: -50px;
      line-height: 32px;
      position: absolute;
      text-align: center;
      top: 2px;
      width: 30px;
    }

    ul {
      list-style: none;
      padding: 0;
      position: relative;
      margin: 20px 0 30px 0;

      li {
        font-family: basic-sans, serif;
        font-size: 18px;
        line-height: 30px;
        text-align: justify;
        font-weight: 400;
        padding-left: 20px;

        &:before {
          content: "·";
          font-size: 36px;
          left: 0;
          line-height: 36px;
          position: absolute;
          color: $school-basis;
        }
      }
    }
  }
}

#over-valies {
  .theoryPage__ul {
    font-size: 18px;
    font-weight: 400;
    text-align: left;

    li {
      margin-bottom: 12px;

      a {
        color: $prikkel-basis;
      }
    }
  }
}

@media (min-width: $bp-l) and (max-width: 1145px) {
  main {
    width: 70%;
  }
}

@media screen and (max-width: $bp-s) {
  .theoryPage__subtitle .theory__title-before {
    display: none;
  }

  .theoryPage__title {
    text-align: left;
  }

  .theoryPage__subtitle {
    margin-top: 40px;
  }

  .theoryPage__image-text {
    width: 100vw;
    padding: 40px;
  }

  aside {
    border-radius: 0;
    width: 100vw;
    padding: 40px;
  }

  .theory__asset,
  .theoryPage__asset {
    width: calc(100vw - 15px);
    left: -30px;
    position: relative;
    max-width: inherit;
  }

  .btn-wrapper {
    flex-direction: column;

    a {
      width: 100%;
      margin: 0;

      &:last-of-type {
        margin-top: 15px;
        margin-left: 0;
      }
    }
  }

  .theoryPage__ol {
    margin-left: 40px;
  }
}
