.footer {
  max-width: 100vw;
  font-family: $basicSans;
  padding: 11px 8vw;

  a.footer__link {
    font-family: $basicSans;
    color: white;
  }
}

/* Types of footers */
.footer--home {
  background-color: $valies-black;
}

.footer--prikkel {
  background-color: $prikkel-basis;
}

.footer--project {
  background-color: $project-basis;
}

.footer--school {
  background-color: $school-basis;
}
