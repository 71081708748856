#navigationBottom {
  // Section with menus
  margin-top: 250px;
  .menus {
    display: flex;
    flex-wrap: wrap;
    margin: 50px 5vw;
    width: 90vw;
    justify-content: space-evenly;

    ul {
      font-size: 14px;
      width: 230px;
      list-style: none;
      padding-left: 0px;
    }
    ul:last-of-type {
      font-weight: 600;
    }
    li {
      margin: 15px 0px;
    }

    .menus__menu {
      &--prikkel {
        a:hover {
          color: $prikkel-basis;
          text-decoration: underline;
        }
        .is-active {
          color: $prikkel-basis;
        }
      }

      &--project {
        a:hover {
          color: $project-basis;
          text-decoration: underline;
        }
        .is-active {
          color: $project-basis;
        }
      }

      &--school {
        a:hover {
          color: $school-basis;
          text-decoration: underline;
        }

        .is-active {
          color: $school-basis;
        }
      }

      &--main {
        a:hover {
          text-decoration: underline;
        }
        li {
          position: relative;
        }
        .is-active::before {
          background: url("../assets/icons/algemeen/arrow-elbow-down-right.svg");
          filter: invert(1);
          content: "";
          background-size: contain;
          background-repeat: no-repeat;
          width: 8px;
          height: 12px;
          position: absolute;
          top: 4px;
          left: -15px;
        }
      }
    }
    .menus__menu--project {
      a:hover {
        color: $project-basis;
      }
    }

    li:first-of-type {
      font-weight: 600;
      position: relative;

      .navigationBottom__navLink--active.prikkel-arrow::before {
        background: url("../assets/icons/icon_arrow--prikkel@2x.png");
        content: "";
        background-size: contain;
        background-repeat: no-repeat;
        width: 8px;
        height: 12px;
        position: absolute;
        top: 4px;
        left: -15px;
      }

      .navigationBottom__navLink--active.project-arrow::before {
        background: url("../assets/icons/icon_arrow--project@2x.png");
        content: "";
        background-size: contain;
        background-repeat: no-repeat;
        width: 8px;
        height: 12px;
        position: absolute;
        top: 4px;
        left: -15px;
      }
      .navigationBottom__navLink--active.school-arrow::before {
        background: url("../assets/icons/icon_arrow--school@2x.png");
        content: "";
        background-size: contain;
        background-repeat: no-repeat;
        width: 8px;
        height: 12px;
        position: absolute;
        top: 4px;
        left: -15px;
      }
    }
  }

  // Section with sponsors
  .sponsors {
    margin: 60px auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    a {
      margin: 20px 20px;
      height: 50px;
      width: 150px;
      text-align: center;
      vertical-align: center;
      &:last-of-type {
        margin-right: 0px;
      }
    }

    .sponsor {
      height: 40px;
    }
  }
}

@media screen and (max-width: $bp-s) {
    #navigationBottom {
        margin-top: 150px;

        .menus {
            display: flex;
            flex-wrap: wrap;
            margin: 50px 40px;
            width: inherit;
            justify-content: flex-start;
        }
    }
}
