.praktijkvoorbeelden__wrapper {
  margin-top: 60px;
  margin-bottom: 60px;
  width: 1190px;
  max-width: 95vw;
  &--prikkel {
    .praktijkvoorbeeld__asset {
      background-color: $prikkel-basis;

      div:first-of-type {
        &.praktijkvoorbeeld__img--1 {
          background-image: url("../assets/imgs/prikkel/praktijkvoorbeelden/foto8_pexels-marta-ortigosa-3480494.jpg");
        }
        &.praktijkvoorbeeld__img--2 {
          background-image: url("../assets/imgs/prikkel/praktijkvoorbeelden/foto9_pexels-ivan-samkov-5947556.jpg");
        }
        &.praktijkvoorbeeld__img--3 {
          background-image: url("../assets/imgs/prikkel/praktijkvoorbeelden/foto10_pexels-tima-miroshnichenko-7009473.jpg");
        }
        &.praktijkvoorbeeld__img--4 {
          background-image: url("../assets/imgs/prikkel/praktijkvoorbeelden/foto11_pexels-brady-knoll-6108074.jpg");
        }
      }
    }
  }
  &--project {
    .praktijkvoorbeeld__asset {
      background-color: $project-basis;

      div:first-of-type {
        &.praktijkvoorbeeld__img--1 {
          background-image: url("../assets/imgs/project/praktijkvoorbeelden/foto20_voor elkaar.jpg");
        }
        &.praktijkvoorbeeld__img--2 {
          background-image: url("../assets/imgs/project/praktijkvoorbeelden/Foto5-2_Els_Bellem.jpg");
        }
        &.praktijkvoorbeeld__img--3 {
          background-image: url("../assets/imgs/project/praktijkvoorbeelden/foto_consuminderkaart.png");
        }
      }
    }
  }
  &--school {
    .praktijkvoorbeeld__asset {
      background-color: $school-basis;

      div:first-of-type {
        &.praktijkvoorbeeld__img--1 {
          background-image: url("../assets/imgs/school/praktijkvoorbeelden/foto30_pexels-armin-rimoldi-5553065.jpg");
        }
        &.praktijkvoorbeeld__img--2 {
          background-image: url("../assets/imgs/school/praktijkvoorbeelden/foto31_IMG_1604_Thomas_VALIESdag4.jpg");
        }
        &.praktijkvoorbeeld__img--3 {
          background-image: url("../assets/imgs/school/praktijkvoorbeelden/foto32_patrick-perkins-ETRPjvb0KM0-unsplash.jpg");
        }
      }
    }
  }
  .praktijkvoorbeeld__asset {
    overflow: hidden;
    border-radius: 8px;
    margin: 20px auto;
    display: flex;
    flex-direction: row;
    width: 100%;

    div:first-of-type {
      width: 30%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
    div:last-child {
      width: 70%;
      padding: 60px;
      h3 {
        margin-bottom: 20px;
      }
      p {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 40px;
        color: white;
      }
      .btn-light {
        background-color: inherit;
        &:hover {
          background-color: $valies-black;
        }
      }
    }
  }
}

@media screen and (max-width: $bp-m) {
  .praktijkvoorbeeld__asset {
    div:first-of-type {
      display: none;
    }
    div:last-of-type {
      width: 100% !important;
    }
  }
}
