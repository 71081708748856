#home {
  // Section with overview of subwebsites
  .overview {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;

    > div {
      align-items: center;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      display: flex;
      flex-direction: column;
      height: 28vw;
      justify-content: center;
      margin: 0px 10px;
      min-height: 300px;
      min-width: 300px;
      width: 28vw;

      h3 {
        font-family: $spaceMono;
        font-size: 36px;
        font-weight: 600;
        text-align: center;
        color: white;

        span {
          font-family: $basicSans;

          color: $valies-black;
        }
      }

      p {
        font-style: italic;
        text-align: center;
      }
      p,
      a {
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 10px;
      }
    }
  }

  .overview--prikkel {
    background-image: $bgShapePrikkelS;
  }
  .overview--project {
    background-image: $bgShapeProjectS;
  }
  .overview--school {
    background-image: $bgShapeSchoolS;
  }

  .information-wrapper {
    background-color: $valies-black;
    border-radius: 8px;
    padding: 5vw;
    width: 80vw;
    margin: 80px auto 0 auto;
    max-width: 1440px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    gap: 40px;

    .information {
      // flex-basis: 50%;
      // max-width: 600px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 10%;
      align-items: center;
      margin-bottom: 25px;
      width: 100% !important;
      img {
        height: auto;
        width: 40%;
        border-radius: 8px;
        align-self: center;
        justify-self: center;
        max-width: 600px;
      }
      img.home-image--extra {
        display: none;
      }
      &--first {
      }

      &--second {
        flex-direction: row-reverse;
      }
      @media screen and (max-width: $bp-xl) {
        flex-direction: column;
        &--first {
          margin-bottom: -20px;
        }
        img {
          width: 100%;
          margin: auto;
        }
        img.home-image--extra {
          display: inline-block;
        }
        img.home-image--base {
          display: none;
        }
      }

      &__title {
        color: white;
        font-size: 36px;
        line-height: 44px;
        font-weight: bold;
        max-width: 600px;
      }

      &__text {
        font-size: 20px;
        color: $valies-grey;
        line-height: 28px;
        //  margin-bottom: 70px;

        a {
          color: white;
        }
      }

      &__btn {
        align-self: flex-end;
      }

      // &__imgs {
      //   height: 40vw;
      //   max-height: 440px;
      //   @media screen and (max-width: $bp-l) {
      //     height: 100vw;
      //   }
      //   background-size: 100%;
      //   background-position: center top;
      //   background-repeat: no-repeat;
      //   width: 100%;
      //   max-width: 440px;
      // }
    }
  }
}

@media screen and (max-width: $bp-xl) {
  #home {
    .information-wrapper {
      flex-direction: column;
      > div {
        width: 90% !important;
      }
      // > :nth-child(2) {
      //   margin-top: 50px;
      // }
    }
  }
}

@media screen and (max-width: $bp-l) {
  #home {
    .overview {
      > div {
        h3 {
          font-size: 27px;
          margin-bottom: 15px;
        }
      }
    }
  }
}

@media screen and (max-width: $bp-m) {
  #home {
    .overview {
      > div {
        h3 {
          font-size: 24px;
          margin-bottom: 10px;
        }
      }
    }
  }
}

@media screen and (max-width: $bp-s) {
  #home {
    .information-wrapper {
      width: 100%;
      border-radius: 0;
      padding: 40px;

      .information__imgs {
        height: 30vh;
      }
    }
  }
}
