/* imports */
@import "./normalize.css";
@import "bootstrap/dist/css/bootstrap.min.css";
@import "./variables.scss";
@import "./fontastic.css";
@import "./buttons.scss";
@import "./footer.scss";
@import "./header.scss";
@import "./home.scss";
@import "./navigationBottom.scss";
@import "./prikkel/prikkel.scss";
@import "./navigationManualPages.scss";
@import "./theory-pages.scss";
@import "./material-pages.scss";
@import "./praktijkvoorbeelden-overzicht.scss";

html {
}

a:hover {
  text-decoration: none;
  color: $valies-black;
}

body {
  background-color: $valies-bg;
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $basicSans;
}

h1 {
  text-align: center;
  font-weight: 300;

  span {
    font-weight: 600;
  }
}

#home {
  h1 {
    margin: 40px 0px 80px 0px;
  }
}

p {
  font-family: $basicSans;
}

a {
  text-decoration: none;
  font-family: $spaceMono;
  color: $valies-black;
}

.prikkel-color {
  color: $prikkel-basis;
}

.school-color {
  color: $school-basis;
}

.project-color {
  color: $project-basis;
}
