.theoryPage {
  padding: 0px 5vw;
  position: relative;
  scroll-behavior: smooth;

  .navbar-left {
    background-color: $valies-bg;
    flex-direction: column;
    flex-wrap: wrap;
    float: left;
    justify-content: flex-start;
    list-style-type: none;
    margin-right: 20px;
    padding: 50px 0px;
    position: sticky;
    top: 0;
    width: 100%;

    > div:first-of-type {
      width: 100%;
    }

    .navbar-toggler {
      font-family: $basicSans;
      margin-bottom: 30px;
      padding: 0;
      position: relative;

      &:after {
        content: " ";
        display: block;
        width: 77%;
        height: 2px;
        bottom: -5px;
        position: absolute;
        background-color: $valies-black;
      }

      i {
        color: $valies-black;
        display: inline-block;
        position: relative;
        margin-left: 10px;
        top: 2px;
        transform: rotate(0deg);
        transition: all 0.3s;
      }

      &--prikkel {
        &:after {
          background-color: $prikkel-basis;
        }

        i {
          color: $prikkel-basis;
        }
      }

      &--school {
        &:after {
          background-color: $school-basis;
        }

        i {
          color: $school-basis;
        }
      }

      &--project {
        &:after {
          background-color: $project-basis;
        }

        i {
          color: $project-basis;
        }
      }

      &:not(.collapsed) {
        i {
          transform: rotate(180deg);
          transition: all 0.3s;
        }
      }

      &-wrapper {
        display: flex;
        justify-content: space-between;
      }
    }

    .navbar-collapse {
      flex-direction: column;
      height: 100%;
      width: 100%;
    }

    .navbar-left__item {
      align-items: center;
      align-items: flex-start !important;
      border-radius: 5px;
      display: flex;
      font-family: $basicSans;
      font-size: 18px;
      margin-bottom: 4px;
      margin-left: 0;
      padding: 15px 5px 15px 20px;
      transition: all 0.3s;
      width: 200px;

      &--prikkel,
      &--project,
      &--school {
        &:hover {
          color: white;
          margin-left: 0;
          padding-left: 20px;
          text-decoration: none !important;
          transition: all 0.3s;
        }
        &.navbar-left__item--active {
          color: white;
          margin-left: 0;
          padding-left: 20px !important;
        }
      }

      &--prikkel:hover {
        background-color: $prikkel-basis;
      }

      &--project:hover {
        background-color: $project-basis;
      }

      &--school:hover {
        background-color: $school-basis;
      }
    }

    .navbar-left__item--prikkel.navbar-left__item--active {
      background-color: $prikkel-basis;
      border: 1px solid $prikkel-basis;
    }
    .navbar-left__item--project.navbar-left__item--active {
      background-color: $project-basis;
      border: 1px solid $project-basis;
    }
    .navbar-left__item--school.navbar-left__item--active {
      background-color: $school-basis;
      border: 1px solid $school-basis;
    }

    .navbar-left__subitem {
      align-items: center;
      border-radius: 5px;
      display: flex;
      font-family: $basicSans;
      font-size: 16px;
      font-weight: 200;
      line-height: 24px;
      margin-left: 0;
      margin: 0px;
      padding: 10px 5px 10px 40px;
      text-decoration: none !important;
      width: 200px;

      &.navbar-left__subitem--active,
      &:hover {
        transition: all 0.3s;
        font-weight: 600;
      }
      &--prikkel {
        &.navbar-left__subitem--active,
        &:hover {
          color: $prikkel-basis;
        }
      }
      &--project {
        &.navbar-left__subitem--active,
        &:hover {
          color: $project-basis;
        }
      }
      &--school {
        &.navbar-left__subitem--active,
        &:hover {
          color: $school-basis;
        }
      }
    }
  }
}

@media screen and (max-width: $bp-l) {
  .theoryPage {
    .navbar-expand-lg .navbar-toggler {
      display: block;
    }

    .navbar-left {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      position: relative;
      float: inherit;
      max-width: 690px;
      margin: 40px auto;
      padding: 0;

      &__item {
        width: 100% !important;
      }

      &__subitem {
        position: static;
        .navbar-left__item {
          padding: 20px;
          margin-left: 0;
          margin-bottom: 2px;
          max-width: 200px;
          width: auto;
        }
      }
    }
  }
}

@media screen and (min-width: 1101px) {
  .theoryPage {
    .navbar-left {
      margin-top: -30px;
      padding-top: 30px;
      width: auto;
    }

    .navbar-toggler {
      display: none;
    }

    .navbar-collapse {
      display: block !important;
    }
  }
}
