.materiaal__wrapper {
  margin: 60px 0px;
  width: 90vw;
}

.materiaal__asset {
  align-items: center;
  background-color: white;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 15px auto;
  max-width: 90vw;
  padding: 50px 60px;
  width: 1100px;

  &:hover,
  &:active {
    background-color: rgba(255, 255, 255, 0.4);
    transition: all 0.3s; /* vendorless fallback */
    -o-transition: all 0.3s; /* opera */
    -ms-transition: all 0.3s; /* IE 10 */
    -moz-transition: all 0.3s; /* Firefox */
    -webkit-transition: all 0.3s; /*safari and chrome */
    img {
      transform: rotate(10deg);
      transition: all 0.5s; /* vendorless fallback */
      -o-transition: all 0.5s; /* opera */
      -ms-transition: all 0.5s; /* IE 10 */
      -moz-transition: all 0.5s; /* Firefox */
      -webkit-transition: all 0.5s; /*safari and chrome */
    }
  }

  img {
    max-width: 100px;
    margin-right: 10px;
    transform: rotate(0deg);
    transition: all 0.5s; /* vendorless fallback */
    -o-transition: all 0.5s; /* opera */
    -ms-transition: all 0.5s; /* IE 10 */
    -moz-transition: all 0.5s; /* Firefox */
    -webkit-transition: all 0.5s; /*safari and chrome */
  }
  small {
    margin-left: 10px;
    a {
      &:hover {
        transition: all 0.3s;
        text-decoration: underline;
      }
    }
  }

  p {
    font-size: 16px;
    line-height: 24px;
  }
}

.materiaal__info {
  margin-right: auto;
  padding: 0 30px;

  .theoryPage__sub-subtitle {
    margin-top: 0;
  }

  p.small {
    margin: 0;
  }
}

@media screen and (max-width: $bp-m) {
  .materiaal__asset {
    padding: 30px 30px;

    h3.theoryPage__sub-subtitle {
      font-size: 18px !important;
    }
  }
}

@media screen and (max-width: 600px) {
  .prikkel-color {
    display: none;
  }
  .materiaal__info {
    padding-right: 0;
  }
}
