.tegeloverzicht__wrapper {
  display: flex;
  justify-content: center;
}
.tegeloverzicht {
  width: 90vw;
  margin: 60px auto;
  align-self: center;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 340px));
  gap: 40px !important;
  justify-content: center;

  .subchapter__tile {
    max-width: 340px;
    min-width: 300px;
    // width: 30vw;
    // height: 30vw;
    max-height: 340px;
    min-height: 300px;

    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    top: 0;

    &:hover {
      .subchapter__img {
        -ms-transform: scale(1.05);
        transform: scale(1.05);
        -webkit-transform: scale(1.05);
        transition: transform 0.3s;
      }
    }

    .subchapter__img-wrapper {
      display: block;
      height: 100%;
      width: 100%;
      overflow: hidden;
      position: relative;
      border-radius: 8px 8px 0px 0px;
    }
    .subchapter__img {
      max-width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      margin: 0px;
      -ms-transform: scale(1.01);
      transform: scale(1.01);
      -webkit-transform: scale(1.01);
      transition: transform 0.3s;

      &--prikkel1 {
        background-image: url("../assets/imgs/prikkel/introductie/foto4a_speeddate.jpg");
      }
      &--prikkel2 {
        background-image: url("../assets/imgs/prikkel/introductie/Foto4_Els_Bellem.jpg");
      }
      &--prikkel3 {
        background-image: url("../assets/imgs/prikkel/introductie/foto5_S_0349.jpg");
      }
      &--prikkel4 {
        background-image: url("../assets/imgs/prikkel/introductie/foto7_20190604_094454_Thomas_VALIESdag4.jpg");
      }
      &--prikkel5 {
        background-image: url("../assets/imgs/prikkel/introductie/foto6_2dekleuter_Thomas_Langemark.jpg");
      }

      &--project1 {
        background-image: url("../assets/imgs/project/introductie/foto14_S_0386.jpg");
      }
      &--project2 {
        background-image: url("../assets/imgs/project/introductie/foto15_2BD4FCB5-E7DE-44D9-9B2E-DAC87F019C14_Thomas_St-Paulus.jpg");
      }
      &--project3 {
        background-image: url("../assets/imgs/project/introductie/foto16_S_0426.jpg");
      }
      &--project4 {
        background-image: url("../assets/imgs/project/introductie/Foto17_1_Els_Bellem.jpg");
      }
      &--project5 {
        background-image: url("../assets/imgs/project/introductie/foto18_181011_GRAEGO_007_Thomas_1819VALIESdag1.jpg");
      }
      &--project6 {
        background-image: url("../assets/imgs/project/introductie/foto19_181011_GRAEGO_004_Thomas_1819VALIESdag1.jpg");
      }

      &--school1 {
        background-image: url("../assets/imgs/school/introductie/foto27_figuur-edo-eff-school.jpg");
      }
      &--school2 {
        background-image: url("../assets/imgs/school/introductie/Foto29_1_Els_Bellem.jpg");
      }
    }
    .subchapter__title {
      align-items: center;
      border-radius: 0px 0px 8px 8px;
      display: flex;
      height: 20%;
      padding: 20px;
      width: 100%;

      h4 {
        color: white;
        margin: 0px;
        font-size: 18px;
        font-weight: 300px;
      }

      &--prikkel {
        background-color: $prikkel-basis;
      }
      &--project {
        background-color: $project-basis;
      }
      &--school {
        background-color: $school-basis;
      }
    }
  }
}
