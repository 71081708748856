@charset "UTF-8";

@font-face {
  font-family: "untitled-font-3";

  src: url("../assets/fonts/untitled-font-3.eot");
  src: url("../assets/fonts/untitled-font-3.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/untitled-font-3.woff") format("woff"),
    url("../assets/fonts/untitled-font-3.ttf") format("truetype"),
    url("../assets/fonts/untitled-font-3.svg#untitled-font-3") format("svg");
  font-weight: normal;
  font-style: normal;
}

[data-icon]:before {
  font-family: "untitled-font-3" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "untitled-font-3" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-bars:before {
  content: "\61";
}
.icon-angle-down:before {
  content: "\62";
}
.icon-angle-right:before {
  content: "\64";
}
.icon-angle-left:before {
  content: "\63";
}
.icon-angle-up:before {
  content: "\65";
}
.icon-chevron-down:before {
  content: "\66";
}
.icon-chevron-left:before {
  content: "\67";
}
.icon-chevron-right:before {
  content: "\68";
}
.icon-chevron-up:before {
  content: "\69";
}
.icon-sort-asc:before {
  content: "\6a";
}
.icon-sort-desc:before {
  content: "\6b";
}
.icon-times:before {
  content: "\6c";
}
.icon-down-open-big:before {
  content: "\6d";
}
.icon-left-open-big:before {
  content: "\6e";
}
.icon-right-open-big:before {
  content: "\6f";
}
.icon-up-open-big:before {
  content: "\70";
}
.icon-menu:before {
  content: "\71";
}
.icon-arrow-down:before {
  content: "\72";
}
.icon-arrow-down-1:before {
  content: "\74";
}
