.btn-light,
.btn-dark {
  padding: 16px 30px 18px 30px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;

  .btn-text + .icon-chevron-right,
  .btn-text + .icon-arrow-down-1 {
    margin-left: 7px;
  }
  .icon-chevron-left + .btn-text {
    margin-left: 7px;
  }
  .icon-chevron-right,
  .icon-chevron-left {
    font-size: 10px;
  }
  .icon-arrow-down-1 {
    font-size: 12px;
  }

  &:focus {
    outline: none;
    box-shadow: none !important;
  }
}

.navbar-bottom {
  .btn-light,
  .btn-dark {
    .icon-chevron-right,
    .icon-chevron-left {
      margin-top: 4px;
    }
  }
}

.btn-light {
  font-family: $spaceMono;
  background-color: $valies-bg;
  border: 2px solid $valies-grey;
  border-radius: 4px;
  color: $valies-black;
  transition: all 0.3s;

  &:hover {
    background-color: $valies-black;
    border: 2px solid $valies-black;
    color: white;
    transition: all 0.3s;
  }
}
.btn-light--hoverless {
  &:hover {
    background-color: $valies-bg !important;
    border: 2px solid $valies-grey !important;
    color: $valies-black !important;
  }
}
.btn-dark {
  font-family: $spaceMono;
  background-color: $valies-black;
  border: 2px solid $valies-black;
  border-radius: 4px;
  color: white;
  transition: all 0.3s;

  &:hover {
    transition: all 0.3s;
    background-color: $valies-bg;
    color: $valies-black;
    border: 2px solid $valies-grey;
  }
}

.homemenu__navLink,
.pagemenu__navlink {
  font-size: 16px;
  margin-left: 30px;
}

@media screen and (max-width: $bp-m) {
  .navbar-bottom {
    .btn-light,
    .btn-dark {
      .btn-text {
        display: none;
      }
      .btn-text + .icon-chevron-right  {
        margin-left: 0px !important;
      }
      .btn-text + .icon-chevron-right  {
        margin-right: 0px !important;
      }
    }
  }
}
