/* Colors */
$prikkel-basis: #888ac7;
$school-basis: #ff776c;
$project-basis: #e6ba00;
$valies-bg: #f7f4f0;

$valies-green: #6c8e6a;
$valies-black: #2c1138;
$valies-grey: #c3babf;

$valies-onderzoekend: #6C8E6A;
$valies-samen: #4B64C8;
$valies-actiegericht: #EF9618;

$unnamed-color-ef9618: #ef9618;

$unnamed-color-8bb8ff: #8bb8ff;
$unnamed-color-ffa0a0: #ffa0a0;
$unnamed-color-ffeaa5: #ffeaa5;
$unnamed-color-ffca89: #ffca89;
$unnamed-color-ffb9e1: #ffb9e1;
$unnamed-color-c7dcdd: #c7dcdd;
$unnamed-color-908292: #908292;
$unnamed-color-888ac7: #4b64c8;

/* Fonts */
$spaceMono: "Space Mono", sans-serif;
$basicSans: basic-sans, serif;

/* Screen size breakpoints */
$bp-xl: 1400px;
$bp-l: 1024px;
$bp-m: 768px;
$bp-s: 480px;

/* Icons */
$bgShapePrikkelS: url("../assets/icons/prikkel/background_portaal_prikkel.svg");
$bgShapeProjectS: url("../assets/icons/project/background_portaal_project.svg");
$bgShapeSchoolS: url("../assets/icons/school/background_portaal_school.svg");
