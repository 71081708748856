@media screen and (max-width: $bp-l) {
  .headerHome,
  .headerPage {
    display: none !important;
  }
  .headerPageMobile {
    display: block !important;
    position: sticky !important;
    top: 0;
  }
}
@media screen and (min-width: $bp-l) {
  .headerHome,
  .headerPage {
    display: auto !important;
  }
  .headerPageMobile {
    display: none !important;
  }
}

.headerHome {
  display: flex;
  flex-direction: row;
  max-width: 100vw;
  align-items: center;
  justify-content: space-between;
  padding: 30px 8vw;
  background-color: $valies-bg;

  img {
    width: 20vw;
    max-width: 182px;
    // margin-right: 30px;
  }

  .homemenu,
  .pagemenu {
    align-items: center;
  }

  .homemenu__navLink:not(:last-of-type) {
    padding: 10px 10px 8px 10px;

    &:hover {
      border-bottom: 2px solid $valies-black;
      transition: border 0.1s;
    }
  }
}

/* Page header(s) */

.headerPage {
  .main-menu {
    display: flex;
    justify-content: space-between;
    height: 40px;
    align-items: center;
    font-size: 14px;
    padding: 0px 6vw;

    &--prikkel {
      background-color: $prikkel-basis;
    }

    &--project {
      background-color: $project-basis;
    }

    &--school {
      background-color: $school-basis;
    }

    .main-menu__navLink {
      color: white;
      padding: 10px 15px 9px 15px;

      &:active {
        background-color: $valies-black;
      }
      &:hover {
        transition: all 0.3s;
        background-color: $valies-bg;
        color: $valies-black;
      }
      &--active {
        background-color: $valies-black;
      }
    }
  }

  .page-menu {
    height: 130px;
    padding: 0px 6vw;
    display: flex;
    justify-content: space-between;
    &--left {
      display: flex;
      align-items: center;
      img {
        width: 20vw;
        max-width: 182px;
        // margin-right: 30px;
      }
    }

    &--right {
      display: flex;
      align-items: center;
      justify-content: right;
      @media screen and (max-width: $bp-l) {
        flex-direction: column;
        font-size: 12px;
        align-items: flex-end;
        justify-content: space-evenly;
        text-align: right;
      }
      a.page-menu__navLink {
        font-size: 16px;
        // padding: 0px 15px;
        margin: 0px 15px;
        font-weight: 600;
        min-width: 150px;
        padding-bottom: 5px;

        @media screen and (max-width: $bp-m) {
          font-size: 12px;
        }

        &.prikkel-color--active {
          &:hover,
          &:focus,
          &:active {
            transition: all 0.3s;
            // border-bottom: 2px solid $prikkel-basis;
            color: $prikkel-basis;
            position: relative;
            &:after {
              content: "";
              background-color: $prikkel-basis;
              height: 2px;
              left: 0;
              width: 100%;
              position: absolute;
              top: 25px;
            }
          }
          &.prikkel-color--selected {
            // border-bottom: 2px solid $prikkel-basis;
            color: $prikkel-basis;
            position: relative;
            &:after {
              content: "";
              background-color: $prikkel-basis;
              height: 2px;
              left: 0;
              width: 100%;
              position: absolute;
              top: 25px;
            }
          }
        }
        &.project-color--active {
          &:hover,
          &:focus,
          &:active {
            transition: all 0.3s;
            // border-bottom: 2px solid $project-basis;
            color: $project-basis;
            position: relative;

            &:after {
              content: "";
              background-color: $project-basis;
              height: 2px;
              left: 0;
              width: 100%;
              position: absolute;
              top: 25px;
            }
          }
          &.project-color--selected {
            // border-bottom: 2px solid $project-basis;
            color: $project-basis;
            position: relative;

            &:after {
              content: "";
              background-color: $project-basis;
              height: 2px;
              left: 0;
              width: 100%;
              position: absolute;
              top: 25px;
            }
          }
        }
        &.school-color--active {
          &:hover,
          &:focus,
          &:active {
            transition: all 0.3s;
            // border-bottom: 2px solid $school-basis;
            color: $school-basis;
            position: relative;

            &:after {
              content: "";
              background-color: $school-basis;
              height: 2px;
              left: 0;
              width: 100%;
              position: absolute;
              top: 25px;
            }
          }
          &.school-color--selected {
            // border-bottom: 2px solid $school-basis;
            color: $school-basis;
            position: relative;

            &:after {
              content: "";
              background-color: $school-basis;
              height: 2px;
              left: 0;
              width: 100%;
              position: absolute;
              top: 25px;
            }
          }
        }
      }
    }
    h4 {
      display: inline-block;
      text-transform: capitalize;
      font-size: 30px;
      font-weight: 300;
      min-width: 150px;
      span {
        opacity: 0.3;
        font-weight: 100;
        margin-right: 5px;
        margin-left: 15px;
      }
      @media screen and (max-width: $bp-m) {
        display: none;
      }
    }
  }
}

// Hamburger menu - mobile
.headerPageMobile__wrapper {
  @media screen and (min-width: $bp-l) {
    display: none;
  }
}

.bm-burger-button {
  width: 50px !important;
  height: 40px !important;
  z-index: 2;
  color: blue !important;
  border-radius: 4px;
  border: 1px solid $valies-grey;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    display: flex;
    height: 14px;
    width: 20px;
    flex-direction: column;
    justify-content: space-between;
  }
  .bm-burger-bars {
    // display: none;
    position: static !important;
    height: 2px !important;
    width: 20px !important;
    border-radius: 2px;
    background-color: $valies-black !important;
    margin-bottom: 0px;
  }
}

.headerPageMobile {
  width: 100%;
  background: $valies-bg;
  height: 60px;
  color: white !important;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 1;
  .headerPageMobile__left {
    font-family: $spaceMono;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 25px;
    height: 100%;
    img {
      height: 40px;
    }
    h4 {
      display: inline;
      font-size: 30px;
      margin: 0px 15px;
      span {
        font-family: $spaceMono;
        font-size: 20px;
        font-weight: 100;
        margin-left: 5px;
        text-transform: capitalize;
      }
    }
  }
}
/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  right: 25px;
  top: 15px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: white;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: white;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;

  top: 30px !important;
  right: 30px !important;
  position: relative;
  margin-right: 15px;
}

/* Color/shape of close button cross */
.bm-cross {
  // background: white;
  font-size: 20px;
  width: 100%;
  height: 100%;
  font-family: $basicSans;
}
#react-burger-cross-btn {
  position: absolute !important;
  // top: 60px;
  right: 30px;
  &:before {
    content: "SLUITEN";
    position: relative;
    color: white;
    font-family: $basicSans !important;
    font-weight: bold !important;

    font-size: 14px !important;
    right: 65px;
    // top: 18px;
  }
  &:hover {
    &:before {
      text-decoration: underline;
    }
  }
}
.bm-cross {
  // position: absolute !important;
  // right: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  .cross-line {
    height: 2px !important;
    width: 21px;
    border-radius: 2px;
    background: white;
  }

  .cross-line1 {
    transform: rotate(45deg);
    position: relative;
    top: 1px;
  }
  .cross-line2 {
    top: -1px;
    position: relative;
    transform: rotate(-45deg);
  }
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  z-index: 1;
  position: fixed;
  height: 100%;
  top: 0;
  display: flex;
  justify-content: flex-end;
}

/* General sidebar styles */
.bm-menu {
  position: relative;
  background: $valies-black;
  padding: 2.5em 1.5em 0;
  width: 100vw;

  .mobile-menu__item {
    list-style-type: none;
    margin-left: 0;
    color: white;
    margin-top: 0px;

    li {
      margin: 15px 0px;
      a {
        color: white;
        text-transform: lowercase;
        font-size: 15px;
        font-weight: 100;
      }
    }
    li:first-of-type {
      a {
        color: white;
        text-transform: inherit;
        font-weight: 300;
        font-size: 20px;
        font-weight: 600 !important;
      }

      position: relative;

      .navigationBottom__navLink--active.prikkel-arrow::before {
        background: url("../assets/icons/icon_arrow--prikkel@2x.png");
        content: "";
        background-size: contain;
        background-repeat: no-repeat;
        width: 8px;
        height: 12px;
        position: absolute;
        top: 8px;
        left: -15px;
      }
      .navigationBottom__navLink--active.project-arrow::before {
        background: url("../assets/icons/icon_arrow--project@2x.png");
        content: "";
        background-size: contain;
        background-repeat: no-repeat;
        width: 8px;
        height: 12px;
        position: absolute;
        top: 8px;
        left: -15px;
      }
      .navigationBottom__navLink--active.school-arrow::before {
        background: url("../assets/icons/icon_arrow--school@2x.png");
        content: "";
        background-size: contain;
        background-repeat: no-repeat;
        width: 8px;
        height: 12px;
        position: absolute;
        top: 8px;
        left: -15px;
      }
    }
  }

  .mobile-menu__school,
  .mobile-menu__prikkel,
  .mobile-menu__project {
    margin: 40px 0px;
  }

  .mobile-menu__prikkel {
    li a:hover {
      color: $prikkel-basis !important;
      text-decoration: underline;
    }
    li .is-active {
      color: $prikkel-basis !important;
    }
  }
  .mobile-menu__project {
    li a:hover {
      color: $project-basis !important;
      text-decoration: underline;
    }
    li .is-active {
      color: $project-basis !important;
    }
  }
  .mobile-menu__school {
    position: relative;
    li a:hover {
      color: $school-basis !important;
      text-decoration: underline;
    }
  }

  .mobile-menu__rest a {
    font-family: $basicSans;
    &:hover {
      text-decoration: underline;
    }
  }

  .mobile-menu__rest {
    position: relative;
    .is-active::before {
      background: url("../assets/icons/algemeen/arrow-elbow-down-right.svg");
      content: "";
      background-size: contain;
      background-repeat: no-repeat;
      width: 8px;
      height: 12px;
      position: absolute;
      top: 8px;
      left: -15px;
    }
  }
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: $valies-black;
}

/* Wrapper for item list */
.bm-item-list {
  color: white;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: $valies-black;
}

@media screen and (max-width: $bp-m) {
  .bm-item-list {
    margin: 0px auto;
  }
  .bm-menu-wrap {
    width: 100vw !important;
  }
}
