#prikkel-home,
#school-home,
#project-home {
  position: relative;
  overflow: hidden;
  display: flex;
  min-width: 100vw;
  flex-direction: column;
  align-items: center;

  section {
    max-width: 1190px;
    width: 90vw;
    margin: 60px 0px;
    display: flex;
    // flex-wrap: wrap;
    flex-direction: row-reverse;
    justify-content: space-between;

    &.overview-chapter--mirrored {
      flex-direction: row;

      .overview-chapter__img {
        margin-right: 0px;
        margin-left: 30px;
      }
    }

    .overview-chapter__info {
      max-width: 440px;
      @media screen and (max-width: $bp-m) {
        max-width: 100%;
      }

      .overview-chapter__subtitle {
        font-weight: 300;
        font-size: 16px;
        font-family: $spaceMono;
        margin-bottom: 20px;
        font-weight: bold;
      }
      .overview-chapter__title {
        font-size: 36px;
        margin-top: 0px;
        font-weight: bold;
      }
      .overview-chapter__description {
        font-size: 18px;
        line-height: 30px;
        margin-bottom: 50px;
      }
    }
    .overview-chapter__img {
      width: 50%;
      max-width: 565px;
      height: 50vw;
      max-height: 450px;
      border-radius: 8px;
      margin-right: 30px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      @media screen and (max-width: $bp-m) {
        display: none;
      }
    }
  }

  .overview-top {
    height: 70vh;
    width: 100vw;
    max-width: 1190px;
    width: 90vw;

    .overview-top__info {
      height: 100%;
      width: 60%;
      max-width: 550px;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      @media screen and (max-width: $bp-l) {
        width: 90%;
      }
      .overview-top__title {
        font-size: 44px;
        font-weight: bold;

        @media screen and (max-width: $bp-l) {
          font-size: 36px;
        }
      }
    }
    .overview-top__shape {
      width: 520px;
      z-index: -1;
      position: absolute;
      top: 0;
      right: -200px;
    }
  }
}

#prikkel-home {
    .overview-chapter__img {
        &--1 {
            background-image: url("../assets/imgs/prikkel/foto1_S_0404.jpg");
        }
        &--2 {
            background-image: url("../assets/imgs/prikkel/foto2_F0442D42-EA11-4468-B2E4-10CB50492313_Thomas_St-Paulus.jpg");
        }
        &--3 {
            background-image: url("../assets/imgs/prikkel/Foto3_1_Els_Bellem.jpg");
        }
    }
}

#project-home {
    .overview-chapter__img {
        &--1 {
            background-image: url("../assets/imgs/project/foto12_S_0437.jpg");
        }
        &--2 {
            background-image: url("../assets/imgs/project/foto13_IMG_1616_Thomas_VALIESdag4.jpg");
        }
        &--3 {
            background-image: url("../assets/imgs/project/foto13b_181011_GRAEGO_005_Thomas_1819VALIESdag1.jpg");
        }
    }
}

#school-home {
    .overview-chapter__img {
        &--1 {
            background-image: url("../assets/imgs/school/foto24_181011_GRAEGO_002_Thomas_1819VALIESdag1.jpg");
        }
        &--2 {
            background-image: url("../assets/imgs/school/foto25_20190604_143849_Thomas_VALIESdag4.jpg");
        }
        &--3 {
            background-image: url("../assets/imgs/school/foto26_IMG_1608_Thomas_VALIESdag4.jpg");
        }
    }
}

@media screen and (max-width: $bp-s) {
    #prikkel-home .overview-top, #school-home .overview-top, #project-home .overview-top {
        height: 50vh;
    }

    #prikkel-home .overview-top .overview-top__shape, #school-home .overview-top .overview-top__shape, #project-home .overview-top .overview-top__shape {
        width: 240px;
        z-index: -1;
        position: absolute;
        top: 250px;
        right: -40px;
    }
}

