@import "../overview-pages.scss";
@import "../tile-pages.scss";

.sdg-wrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 5px;
  // width: 100%;
  margin: 20px auto 50px auto;
  justify-content: left;
  .sdg-img {
    width: 19.3%;

    @media screen and (max-width: $bp-m) {
      width: 24%;
    }
    @media screen and (max-width: $bp-s) {
      width: 32%;
    }
  }
}
